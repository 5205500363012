import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { ManagerLayoutComponent } from './shared/layouts/manager-layout/manager-layout.component';
import { AuthGuardGuard } from './guards/auth-guard.guard';
import { EmpstatusComponent } from './manager/empstatus/empstatus.component';
const routes: Routes = [
  {
    path: '',
    component: AuthComponent
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'manager',
    loadChildren: () => import('./manager/manager.module').then(m => m.ManagerModule)
  },
  {
    path: 'esp',
    component: ManagerLayoutComponent,
    children: [
      {
        path: 'emp-status',
        component: EmpstatusComponent,
        canActivate: [AuthGuardGuard],
      } 
    ]
  },
  {
    path: '**',
    component: PageNotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
