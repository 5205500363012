import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/material-module';
import { ChartsModule } from 'ng2-charts';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { DialogComponent } from './dialog/dialog.component';
import { ConfirmationsDialogComponent } from './confirmations-dialog/confirmations-dialog.component';
import { CheckedSubqPipe } from './pipes/checked-subq.pipe';
import { YearfilterPipe } from './pipes/yearfilter.pipe';
import { StringFilterByPipe } from './pipes/string-filter-by.pipe';
import { SelectCheckAllComponent } from './select-check-all/select-check-all.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { ManagerLayoutComponent } from './layouts/manager-layout/manager-layout.component';
import { EmpstatusComponent } from '../manager/empstatus/empstatus.component';
import { UpdatestatusDialogComponent } from '../manager/component/updatestatus-dialog/updatestatus-dialog.component';



@NgModule({
  declarations: [
    // compoents
    HeaderComponent, FooterComponent, DialogComponent, ConfirmationsDialogComponent,
    SelectCheckAllComponent, AdminLayoutComponent, ManagerLayoutComponent, EmpstatusComponent, UpdatestatusDialogComponent,
    // pipes
    CheckedSubqPipe, YearfilterPipe, StringFilterByPipe
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ChartsModule,
    HttpClientModule,
    FormsModule, ReactiveFormsModule,
    NgxSpinnerModule,
    NgxMatSelectSearchModule,
    RouterModule,
  ],
  exports: [
    // shared module
    MaterialModule,
    ChartsModule,
    HttpClientModule,
    FormsModule, ReactiveFormsModule,
    NgxSpinnerModule,
    NgxMatSelectSearchModule,
    RouterModule,
// shared component
  HeaderComponent, FooterComponent, DialogComponent,
   ConfirmationsDialogComponent,SelectCheckAllComponent,AdminLayoutComponent,ManagerLayoutComponent,
  // shared pipes
  CheckedSubqPipe, YearfilterPipe, StringFilterByPipe
  ],
  entryComponents: [DialogComponent, ConfirmationsDialogComponent,UpdatestatusDialogComponent
   ],
})
export class SharedModule { }
