export class Socialusers {
    provider: string;
    id: string;
    email: string;
    name: string;
    picture: string;
    token?: string;
    idToken?: string;
    // tslint:disable-next-line:variable-name
    access_token?: string;
    // tslint:disable-next-line:variable-name
    role_name?: string;
    // tslint:disable-next-line:variable-name
    project_id?: string;
}
