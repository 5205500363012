import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/internal/operators';
import { Socialusers } from 'src/app/models/socialusers';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../shared/dialog/dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  socialusers = new Socialusers();
  token;
  constructor(public router: Router, public dialog: MatDialog, private spinner: NgxSpinnerService) {
    this.token = this.socialusers.access_token;
  }
  alertDialog(obj) {
    this.dialog.open(DialogComponent, {
      width: '400px',
      data: obj
    });
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.socialusers = JSON.parse(localStorage.getItem('socialusers'));
    this.token = this.socialusers ? this.socialusers.access_token : undefined;
    if (this.token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + this.token
        }
      });
    } else {
      this.router.navigate([`/`]);
    }

    /**
     * continues request execution
     */
    return next.handle(request).pipe(catchError((error, caught) => {
      // intercept the respons error and displace it to the console
      console.log(error);
      this.handleAuthError(error);
      return of(error);
    }) as any);
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    this.spinner.hide();
    // handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
      // navigate /delete cookies or whatever
      localStorage.clear();
      this.router.navigate([`/`]);
      // tslint:disable-next-line:max-line-length
      // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
      return of(err.message);
    }
    if (err.status === 400) {
      // const info = {
      //   msg: err.error['msg'],
      //   type: 'Alert',
      // };
      // this.alertDialog(info);
      alert(err.error['msg'])
    }
    if (err.status === 500) {
      const info = {
        msg: 'Internal Server Error.',
        type: 'Alert',
      };
      this.alertDialog(info);
    }
    throw err;
  }
}
