import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Socialusers } from 'src/app/models/socialusers';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ManagerService {
  public apiBaseUrl = environment.apiBaseUrl;
  socialusers = new Socialusers();
  token;
  fullScreen: boolean = false;
  constructor(public http: HttpClient) {
    this.socialusers = JSON.parse(localStorage.getItem('socialusers'));
    this.token = this.socialusers.access_token;
  }

  getAssignedQuestions() {
    const url = this.apiBaseUrl + 'api/answer-questions';
    return this.http.get(url);
  }
  getPendingQuestions(date) {
    const url = this.apiBaseUrl + 'api/answer-questions?due_date=' + date;
    return this.http.get(url);
  }
  postManagerResponse(data) {
    const url = this.apiBaseUrl + 'api/answer-questions';
    return this.http.put(url, data);
  }
  postPendingManagerResponse(date, data) {
    const url = this.apiBaseUrl + 'api/answer-questions?due_date=' + date;
    return this.http.put(url, data);
  }
  employeeReport() {
    const url = this.apiBaseUrl + 'api/reportees';
    return this.http.get(url);
  }
  getReportiesData(date) {
    const url = this.apiBaseUrl + 'api/feedback?report_month=' + date;
    return this.http.get(url);
  }
  updateReportiesData(data, id) {
    const url = this.apiBaseUrl + 'api/feedback/' + id;
    return this.http.put(url, data);
  }
  le3ManagerList() {
    const url = this.apiBaseUrl + 'api/l3-manager-report';
    return this.http.get(url)
  }
  l3ManagerDetail(id) {
    const url = this.apiBaseUrl +  'api/l3-manager-detail-report?manager_id=' + id;
    return this.http.get(url)
  }
}
