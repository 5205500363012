import { Injectable } from '@angular/core';
import { AdminService } from './admin.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {
  buData: BuData[] = [];
  buOriginalData: BuData[] = [];
  queryParams;
  // tslint:disable-next-line:variable-name
  bussiness_unit_name = '';
  // tslint:disable-next-line:variable-name
  sub_bu_name = '';
  // tslint:disable-next-line:variable-name
  project_name = '';
  searchText = '';
  buFilters: string[] = [];
  subBuFilters: string[] = [];
  projectFilters: string[] = [];
  managerFilter: string[] = [];
  statusFilter: string[] = [];

  businesFilter;
  subBusinesFilter;
  projectDataFilter;
  managerDataFilter;
  statusDataFilter;
  constructor(public adminService: AdminService, private spinner: NgxSpinnerService) { }

  prepareInitialFilters() {
    this.buData.forEach(element => {
      if (this.buFilters.indexOf(element.bu_name) === -1) {
        this.buFilters.push(element.bu_name);
      }
      if (this.subBuFilters.indexOf(element.sub_bu_name) === -1) {
        this.subBuFilters.push(element.sub_bu_name);
      }
      if (this.projectFilters.indexOf(element.project_name) === -1) {
        this.projectFilters.push(element.project_name);
      }
      if (this.managerFilter.indexOf(element.manager_name) === -1) {
        this.managerFilter.push(element.manager_name);
      }
      if (this.statusFilter.indexOf(element.status) === -1) {
        this.statusFilter.push(element.status);
      }
    });
  }

  getAssignQuestions() {
    this.spinner.show();
    this.adminService.getManagerList().subscribe(
      (response: any) => {
        this.buOriginalData = response.data;
        this.buData = response.data;
        this.prepareInitialFilters();
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  prepareSubFilters() {
    this.subBusinesFilter = '';
    this.subBuFilters = [];
    const currentSubFilters = this.buData.map((a) => a.sub_bu_name);
    currentSubFilters.forEach(subfilter => {
      if (this.subBuFilters.indexOf(subfilter) === -1) {
        this.subBuFilters.push(subfilter);
      }
    });
  }

  prepareProjFilters() {
    this.projectDataFilter = '';
    this.projectFilters = [];
    const currentProjFilters = this.buData.map((a) => a.project_name);
    currentProjFilters.forEach(projfilter => {
      if (this.projectFilters.indexOf(projfilter) === -1) {
        this.projectFilters.push(projfilter);
      }
    });
  }
  prepareManagerFilters() {
    this.managerDataFilter = '';
    this.managerFilter = [];
    const currentManagerFilters = this.buData.map((a) =>a.manager_name);
    currentManagerFilters.forEach(managerFilter => {
      if (this.managerFilter.indexOf(managerFilter) === -1) {
        this.managerFilter.push(managerFilter);
      }
    });
  }
  prepareStatusFilters() {
    this.statusDataFilter = '';
    this.statusFilter = [];
    const currentStatusFilters = this.buData.map((a) => a.status);
    currentStatusFilters.forEach(statusfilter => {
      if (this.statusFilter.indexOf(statusfilter) === -1) {
        this.statusFilter.push(statusfilter);
      }
    });
  }

  clearFilter() {
    this.businesFilter = this.subBusinesFilter = this.projectDataFilter = this.managerDataFilter = this.statusDataFilter = '';
    this.buData = this.buOriginalData;
    this.prepareInitialFilters();
  }

  isNullOrEmpty(val) {
    return !val || val === '';
  }

  onFilterChange(type: filterType) {
    switch (type) {
      case filterType.bu:
        this.buData = this.buOriginalData.filter(x => x.bu_name === this.businesFilter);
        this.prepareSubFilters();
        this.prepareProjFilters();
        this.prepareManagerFilters();
        this.prepareStatusFilters();
        break;
      case filterType.subbu:
        // tslint:disable-next-line:max-line-length
        this.buData = !this.isNullOrEmpty(this.businesFilter) ? this.buOriginalData.filter(x => x.sub_bu_name === this.subBusinesFilter && x.bu_name === this.businesFilter) : this.buOriginalData.filter(x => x.sub_bu_name === this.subBusinesFilter);
        this.prepareProjFilters();
        this.prepareManagerFilters();
        this.prepareStatusFilters();
        break;
      case filterType.project:
        let tempProjBu = this.buOriginalData.filter(x => x.project_name === this.projectDataFilter);
        if (!this.isNullOrEmpty(this.businesFilter) && !this.isNullOrEmpty(this.subBusinesFilter)) {
          tempProjBu = tempProjBu.filter(x => x.bu_name === this.businesFilter && x.sub_bu_name === this.subBusinesFilter);
        } else if (!this.isNullOrEmpty(this.businesFilter)) {
          tempProjBu = tempProjBu.filter(x => x.bu_name === this.businesFilter);
        } else if (!this.isNullOrEmpty(this.subBusinesFilter)) {
          tempProjBu = tempProjBu.filter(x => x.sub_bu_name === this.subBusinesFilter);
        }
        this.buData = tempProjBu;
        this.prepareManagerFilters();
        this.prepareStatusFilters();
        break;
      case filterType.manager:
        let tempManagerBu = this.buOriginalData.filter(x => x.manager_name === this.managerDataFilter);
        // tslint:disable-next-line:max-line-length
        if  (!this.isNullOrEmpty(this.businesFilter) && !this.isNullOrEmpty(this.subBusinesFilter) && !this.isNullOrEmpty(this.projectDataFilter)) {
          // tslint:disable-next-line:max-line-length
          tempManagerBu = tempManagerBu.filter(x => x.bu_name === this.businesFilter && x.sub_bu_name === this.subBusinesFilter && x.project_name === this.projectDataFilter);
        // tslint:disable-next-line:max-line-length
        } else if (!this.isNullOrEmpty(this.businesFilter) && !this.isNullOrEmpty(this.subBusinesFilter) && this.isNullOrEmpty(this.projectDataFilter)) {
          tempManagerBu = tempManagerBu.filter(x => x.bu_name === this.businesFilter && x.sub_bu_name === this.subBusinesFilter);
        // tslint:disable-next-line:max-line-length
        } else if (!this.isNullOrEmpty(this.subBusinesFilter) && !this.isNullOrEmpty(this.projectDataFilter) && this.isNullOrEmpty(this.businesFilter)) {
          tempManagerBu = tempManagerBu.filter(x => x.sub_bu_name === this.subBusinesFilter && x.project_name === this.projectDataFilter);
        // tslint:disable-next-line:max-line-length
        } else if (!this.isNullOrEmpty(this.businesFilter) && !this.isNullOrEmpty(this.projectDataFilter) && this.isNullOrEmpty(this.managerDataFilter)) {
          tempManagerBu = tempManagerBu.filter(x => x.bu_name === this.businesFilter && x.sub_bu_name === this.subBusinesFilter);
        // tslint:disable-next-line:max-line-length
        } else if (!this.isNullOrEmpty(this.businesFilter) && this.isNullOrEmpty(this.subBusinesFilter) && this.isNullOrEmpty(this.projectDataFilter)) {
          tempManagerBu = tempManagerBu.filter(x => x.bu_name === this.businesFilter);
        // tslint:disable-next-line:max-line-length
        } else if (this.isNullOrEmpty(this.businesFilter) && !this.isNullOrEmpty(this.subBusinesFilter) && this.isNullOrEmpty(this.projectDataFilter)) {
          tempManagerBu = tempManagerBu.filter(x => x.sub_bu_name === this.subBusinesFilter);
        // tslint:disable-next-line:max-line-length
        } else if (this.isNullOrEmpty(this.businesFilter) && this.isNullOrEmpty(this.subBusinesFilter) && !this.isNullOrEmpty(this.projectDataFilter)) {
          tempManagerBu = tempManagerBu.filter(x => x.project_name === this.projectDataFilter);
        }
        this.buData = tempManagerBu;
        this.prepareStatusFilters();
        break;
      case filterType.status:
        let tempStatusBu = this.buOriginalData.filter(x => x.status === this.statusDataFilter);
        // tslint:disable-next-line:max-line-length
        if (!this.isNullOrEmpty(this.businesFilter) && !this.isNullOrEmpty(this.subBusinesFilter) && !this.isNullOrEmpty(this.projectDataFilter) && !this.isNullOrEmpty(this.managerDataFilter)) {
          // tslint:disable-next-line:max-line-length
          tempStatusBu = tempStatusBu.filter(x => x.bu_name === this.businesFilter && x.sub_bu_name === this.subBusinesFilter && x.project_name === this.projectDataFilter && x.manager_name === this.managerDataFilter);
        // tslint:disable-next-line:max-line-length
        } else if (!this.isNullOrEmpty(this.businesFilter) && !this.isNullOrEmpty(this.subBusinesFilter) && !this.isNullOrEmpty(this.projectDataFilter) && this.isNullOrEmpty(this.managerDataFilter)) {
          // tslint:disable-next-line:max-line-length
          tempStatusBu = tempStatusBu.filter(x => x.bu_name === this.businesFilter && x.sub_bu_name === this.subBusinesFilter && x.project_name === this.project_name);
        // tslint:disable-next-line:max-line-length
        } else if (!this.isNullOrEmpty(this.subBusinesFilter) && !this.isNullOrEmpty(this.projectDataFilter) && !this.isNullOrEmpty(this.managerDataFilter) && this.isNullOrEmpty(this.businesFilter)) {
          // tslint:disable-next-line:max-line-length
          tempStatusBu = tempStatusBu.filter(x => x.sub_bu_name === this.subBusinesFilter && x.project_name === this.projectDataFilter && x.manager_name === this.managerDataFilter);
        // tslint:disable-next-line:max-line-length
        } else if (!this.isNullOrEmpty(this.businesFilter) && !this.isNullOrEmpty(this.projectDataFilter) && !this.isNullOrEmpty(this.managerDataFilter) && this.isNullOrEmpty(this.statusDataFilter)) {
          // tslint:disable-next-line:max-line-length
          tempStatusBu = tempStatusBu.filter(x => x.bu_name === this.businesFilter && x.sub_bu_name === this.subBusinesFilter && x.manager_name === this.managerDataFilter);
        // tslint:disable-next-line:max-line-length
        } else if (!this.isNullOrEmpty(this.businesFilter) && this.isNullOrEmpty(this.subBusinesFilter) && this.isNullOrEmpty(this.projectDataFilter) && this.isNullOrEmpty(this.managerDataFilter)) {
          tempStatusBu = tempStatusBu.filter(x => x.bu_name === this.businesFilter);
        // tslint:disable-next-line:max-line-length
        } else if (this.isNullOrEmpty(this.businesFilter) && !this.isNullOrEmpty(this.subBusinesFilter) && this.isNullOrEmpty(this.projectDataFilter) && this.isNullOrEmpty(this.managerDataFilter)) {
          tempStatusBu = tempStatusBu.filter(x => x.sub_bu_name === this.subBusinesFilter);
        // tslint:disable-next-line:max-line-length
        } else if (this.isNullOrEmpty(this.businesFilter) && this.isNullOrEmpty(this.subBusinesFilter) && !this.isNullOrEmpty(this.projectDataFilter) && this.isNullOrEmpty(this.managerDataFilter)) {
          tempStatusBu = tempStatusBu.filter(x => x.project_name === this.projectDataFilter);
        // tslint:disable-next-line:max-line-length
        } else if (this.isNullOrEmpty(this.businesFilter) && this.isNullOrEmpty(this.subBusinesFilter) && this.isNullOrEmpty(this.projectDataFilter) && !this.isNullOrEmpty(this.managerDataFilter)) {
          tempStatusBu = tempStatusBu.filter(x => x.manager_name === this.managerDataFilter);
        }
        this.buData = tempStatusBu;
        break;
      default:
        break;
    }
  }
}

export interface BuData {
  bu_name: string;
  sub_bu_name: string;
  project_name: string;
  manager_name: string;
  manager_id: string;
  project_id: number;
  status: string;
}

export enum filterType {
  bu,
  subbu,
  project,
  manager,
  status
}

