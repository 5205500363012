import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Socialusers } from 'src/app/models/socialusers';
import { environment } from '../../environments/environment';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Subject } from 'rxjs';
import { BuData } from '../admin/bu-related/bu-related.component';
import { SendQuestions } from '../admin/templates/template-stepper/template-stepper.component';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  socialusers = new Socialusers();
  public apiBaseUrl = environment.apiBaseUrl;
  token;
  previewQuestions = false;
  bu: any;
  subBu: any;
  projects: any;
  manager: any;
  tempGr: any;
  tempL: any;

  businesFilter;
  subBusinesFilter;
  projectDataFilter;
  managerDataFilter;
  manageGradeFiler;
  manageLevelFilter;
  statusDataFilter;
  dedectSideOpened = new Subject<any>();
  dedectSideClose = new Subject<any>();
  buOriginalData: BuData[] = [];
  buData: BuData[] = [];
  scheduler: SendQuestions = {} as SendQuestions;
  constructor(public http: HttpClient) {
    this.socialusers = JSON.parse(localStorage.getItem('socialusers'));
    this.token = this.socialusers.access_token;
  }

  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';

  public exportExcel(jsonData: any[], fileName: string): void {

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }

  clickHandler(){
    let projects = [];
    let grade = [];
    let level = [];
    let mappedProject: any;
    if(this.scheduler['mapped_projects']) {
      mappedProject = this.scheduler['mapped_projects']
    } else {
      mappedProject = this.scheduler['managers']
    }
    mappedProject.forEach(element => {
      projects.push(element.project_name);
      grade.push(element.grade);
      level.push(element.level)
    });
    if(projects) {
      projects = [...new Set(projects)]
    };
    if(grade) {
      grade = [...new Set(grade)]
    };
    if(level) {
      level = [...new Set(level)]
    }
    this.projectDataFilter = projects;
    this.manageGradeFiler = grade;
    this.manageLevelFilter = level;
    let filtersData = {'project':projects, 'grad': grade, 'level': level}
    this.dedectSideOpened.next(filtersData)
}

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }


  getQuestions() {
    const url = this.apiBaseUrl + 'api/questions';
    return this.http.get(url);
  }
  createQuestions(data) {
    const url = this.apiBaseUrl + 'api/questions';
    return this.http.post(url, data);
  }
  editQuestions(data, id) {
    const url = this.apiBaseUrl + 'api/questions/' + id;
    return this.http.put(url, data);
  }
  deletequestions(id) {
    const url = this.apiBaseUrl + 'api/questions/' + id;
    return this.http.delete(url);
  }
  getCategoriesList() {
    const url = this.apiBaseUrl + 'api/questions/categories';
    return this.http.get(url);
  }


  getAssignQuestions() {
    const url = this.apiBaseUrl + 'api/assign-questions';
    return this.http.get(url);
  }
  getManagerList() {
    const url = this.apiBaseUrl + 'api/manager-list?status=any';
    return this.http.get(url)
  }

  getProjectData(id, mngId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      })
    };
    const url = this.apiBaseUrl + 'api/assign-questions/' + id + '?manager_id=' + mngId;
    return this.http.get(url, httpOptions);
  }
  postQuestion(data) {
    const url = this.apiBaseUrl + 'api/assign-questions';
    return this.http.post(url, data);
  }

  getPosteddata(id) {
    const url = this.apiBaseUrl + 'api/assign-questions/' + id;
    return this.http.get(url);
  }
  getAdminDashboard() {
    const url = this.apiBaseUrl + 'api/admin-dashboard';
    return this.http.get(url);
  }
  getProjectList() {
    const url = this.apiBaseUrl + 'api/admin-dashboard?show_project_list=Yes';
    return this.http.get(url);
  }
  getAssignedQ() {
    const url = this.apiBaseUrl + 'api/admin-dashboard?show_current_month_project_status=Yes';
    return this.http.get(url);
  }
  getAssignedMEmpPrj(manager_id) {
    const url = this.apiBaseUrl + 'api/admin-dashboard/manager-emp-prj/'+ manager_id;
    return this.http.get(url)
  }
  getPendingProjectList() {
    const url = this.apiBaseUrl + 'api/admin-dashboard?show_pending_projects=Yes';
    return this.http.get(url);
  }
  removeSubOptions(id) {
    const url = this.apiBaseUrl + 'api/questions/sub-questions/' + id;
    return this.http.delete(url);
  }
  individualEmp(data) {
    const url = this.apiBaseUrl + 'api/individual-report';
    return this.http.post(url, data);
  }
  idvidualEmpQ(data) {
    const url = this.apiBaseUrl + 'api/individual-report?show_questions=Yes';
    return this.http.post(url, data);
  }
  overalReport(data) {
    const url = this.apiBaseUrl + 'api/overall-report';
    return this.http.post(url, data);
  }
  overallProjReport(data) {
    const url = this.apiBaseUrl + 'api/overall-report?show_managers=Yes';
    return this.http.post(url, data);
  }
  createScheduler(data) {
    const url = this.apiBaseUrl + 'api/scheduler';
    return this.http.post(url, data);
  }
  updateScheduler(data) {
    const url = this.apiBaseUrl + 'api/scheduler';
    return this.http.put(url, data);
  }
  deleteScheduler(id) {
    const url = this.apiBaseUrl + 'api/scheduler/' + id;
    return this.http.delete(url);
  }
  getScheduler() {
    const url = this.apiBaseUrl + 'api/scheduler';
    return this.http.get(url);
  }
  getSchedulerById(id) {
    const url = this.apiBaseUrl + 'api/scheduler/' + id;
    return this.http.get(url);
  }
  getRagStatus(date) {
    const url = this.apiBaseUrl + 'api/rag-status?report_month=' + date;
    return this.http.get(url);
  }
  getGrphicalReport(from_date, to_date) {
    const url = this.apiBaseUrl + 'api/graphical-report?report_from_month=' + from_date + '&report_to_month=' + to_date;
    return this.http.get(url)
  }
  getManageReport(from_date, to_date) {
    const url = this.apiBaseUrl + 'api/manager-rag-report?report_month_from=' + from_date + '&report_month_to=' + to_date;
    return this.http.get(url)
  }
  getManageReportR() {
    const url = this.apiBaseUrl + 'api/manager-rag-report';
    return this.http.get(url)
  }
  getSubmissionReport() {
    const url = this.apiBaseUrl + 'api/submission-report';
    return this.http.get(url)
  }
  getSubmissionReportByDate(from_date, to_date) {
    const url = this.apiBaseUrl + 'api/submission-report?report_month_from=' + from_date + '&report_month_to=' + to_date;
    return this.http.get(url)
  }
  getMngrSubmission() {
    const url = this.apiBaseUrl + 'api/manager-answer-report'
    return this.http.get(url)
  }
  getMngrSubMissionByDate(date) {
    const url = this.apiBaseUrl + 'api/manager-answer-report?report_month=' + date;
    return this.http.get(url);
  }
  getMngrSubMission(data) {
    const url = this.apiBaseUrl + 'api/manager-answer-report'
    return this.http.post(url, data)
  }
  getQuestionReport() {
    const url = this.apiBaseUrl + 'api/question-answer-report';
    return this.http.get(url)
  }
  getQuesRepMonthly(from_date, to_date) {
    const url = this.apiBaseUrl + 'api/question-answer-report?report_month_from=' + from_date + '&report_month_to=' + to_date;
    return this.http.get(url)
  }
  getResignationReport() {
    const url = this.apiBaseUrl + 'api/resignation-report';
    return this.http.get(url)
  }
  getResignationByDate(date) {
    const url = this.apiBaseUrl + 'api/resignation-report?report_month=' + date;
    return this.http.get(url)
  }
  rollOutQuestions(data) {
    const url =  this.apiBaseUrl + 'api/roll-out-questions';
    return this.http.post(url, data)
  }
}
