import { Component, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { UpdatestatusDialogComponent } from '../component/updatestatus-dialog/updatestatus-dialog.component';
import { ManagerService } from 'src/app/services/manager.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl } from '@angular/forms';
import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { AdminService } from 'src/app/services/admin.service';
import { Router } from '@angular/router';
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-empstatus',
  templateUrl: './empstatus.component.html',
  styleUrls: ['./empstatus.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class EmpstatusComponent implements OnInit {
  project: any;
  projectMngId;
  projectId;
  empD: any[];
  questionStatuses: string[] = [] as string[];
  columnsToDisplay = ['empId', 'name', 'designation', 'status', 'updated' , 'updatedby', 'updatestatus', ' '];
  expandedElement: Project | null;
  @ViewChild(MatTable, {static: false}) table: MatTable<any>;
  date = new FormControl(moment());
  seletedDate;
  clicks = 0;
  monthname;
  empQuestions;
  espRole: boolean = false;
  constructor(public dialog: MatDialog, public managerService: ManagerService,
              private spinner: NgxSpinnerService, public adminService: AdminService, private router: Router) { }

  ngOnInit() {
    const currentDate = this.date.value._d;
    const t = JSON.stringify(currentDate);
    this.seletedDate = t.slice(1, 11);
    this.getReportiesData();
    if(this.router.url.includes('/esp/emp-status')) {
      this.espRole = true
    }
  }
  showDetails(event: UIEvent, element): void {
    event.stopPropagation();
}
  nextButtonClick() {
    this.clicks += 1;
    const lastDayOfNextMonth = moment(this.seletedDate).add(1, 'months').endOf('month').format('YYYY-MM-DD');
    this.seletedDate = lastDayOfNextMonth;
    this.spinner.show();
    this.managerService.getReportiesData(this.seletedDate).subscribe(
      (response: any) => {
        this.empD = response;
        this.project = response;
        if (response.status == 'success' && response.data.length > 0) {
          this.monthname = response.data[0].employees[0].report_month;
          this.filter();
        } else {
          this.monthname = '';
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  previousButtonClick() {
    this.clicks -= 1;
    const lastDayOfPreviousMonth = moment(this.seletedDate).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
    // Update selected date
    this.seletedDate = lastDayOfPreviousMonth;
    this.spinner.show();
    this.managerService.getReportiesData(this.seletedDate).subscribe(
      (response: any) => {
        this.empD = response;
        this.project = response;
        if (response.status == 'success' && response.data.length > 0) {
          this.monthname = response.data[0].employees[0].report_month;
          this.filter();
        } else {
          this.monthname = '';
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  filter() {
    this.project.data.forEach(element => {
      // tslint:disable-next-line:only-arrow-functions
      const allQstnStatuses = element.employees.map(function(item) { return item.current_status; });
      allQstnStatuses.forEach(status => {
        const index = this.questionStatuses.indexOf(status);
        if (index === -1) {
          this.questionStatuses.push(status);
        }
      });
    });
  }

  getReportiesData() {
    this.spinner.show();
    this.managerService.getReportiesData(this.seletedDate).subscribe(
      (response: any) => {
        this.empD = response;
        this.project = response;
        if (response.status == 'success' && response.data.length > 0) {
          this.monthname = response.data[0].employees[0].report_month;
          this.filter();
        } else {
          this.monthname = '';
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  changeStatusDialog(action, obj, ind) {
    obj.action = action;
    const dialogRef = this.dialog.open(UpdatestatusDialogComponent, {
      width: '650px',
      data: obj,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Update') {
        this.updateStatus(result.data, obj, ind);
      }
    });
  }
  updateStatus(empdata, source, ind) {
    const req = {
      updated_status: empdata.updated_status,
      comments: empdata.comments
    };
    // console.log(req);
    this.spinner.show();
    this.managerService.updateReportiesData(req, empdata.report_card_id).subscribe(
      (data: any) => {
        const projData = this.project.data[ind];
        projData.employees.forEach(employee => {
          if (source.org_emp_id === employee.org_emp_id) {
            employee.updated_status = empdata.updated_status;
            employee.comments = empdata.comments;
            employee.updated_by = data.updated_by;
          }
        });
        this.table.renderRows();
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  getEmpQ(t) {
    this.spinner.show();
    const request = {
      manager_id : this.projectMngId,
      report_date: this.seletedDate,
      emp_id: t.id,
    };
    this.adminService.idvidualEmpQ(request).subscribe(
      (response) => {
        this.empQuestions = response;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  setCurrentProject(project) {
    this.projectMngId = project.manager_id;
    this.projectId = project.project_id;
    console.log(project.project_id)
  }
}

export interface Project {
  'project_name': string;
  'manager_name': string;
  'employees': Employees;
}
export interface Employees {
  'emp_name': string;
  'org_emp_id': number;
  'designation': string;
  'question_answerred_status': string;
  'current_status': string;
}
