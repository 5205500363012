import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { Socialusers } from 'src/app/models/socialusers';
import { AuthServices } from '../services/auth.service';
import { DialogComponent } from '../shared/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import jwt_decode from "jwt-decode";
declare let google: any

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit, AfterViewInit {
  title = 'EWS';
  response;
  socialusers = new Socialusers();
  token;
  returnUrl: string;
  baseUrl = environment.apiBaseUrl;
  googleLoginClientId = environment.googleLoginClientId
  constructor(
    private authServices: AuthServices,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog
  ) { }
  ngOnInit() {
    this.socialusers = JSON.parse(localStorage.getItem('socialusers'));
    const role = this.socialusers ? this.socialusers.role_name : undefined;
    this.returnUrl = this.activatedRoute.snapshot.queryParams.returnUrl || ((role === 'admin') ? ('/admin/dashboard') : ((role === 'manager') ? ('/manager/dashboard') : ('/')));
    if (role) {
      if (role.includes('Admin')) {
        this.router.navigate([`/admin/dashboard`]);
      } else if (role.includes('Manager')) {
        this.router.navigate([`/manager/dashboard`]);
      } else {
        this.router.navigate([`/`]);
      }
    }
  }
  ngAfterViewInit(): void {
    google.accounts.id.initialize({
      client_id: this.googleLoginClientId,
      callback: this.onSignIn.bind(this),
      auto_select: false,
      cancel_on_tap_outside: true,
    });

    google.accounts.id.renderButton(
      document.getElementById("signDiv"),
      { theme: 'filled_blue', shape: 'pill', size: 'large', width: '100%' }
    )  
}
  alertDialog(obj) {
    this.dialog.open(DialogComponent, {
      width: '400px',
      data: obj
    });
  }
 
 async onSignIn(data: any) {
      this.authServices.makeServerSocialLoginCall
        (this.baseUrl + 'api/login', { google_auth_token: data['credential'] }).subscribe((res: any) => {
          this.socialusers = res;
          this.response = res.userDetail;
          localStorage.setItem('userinfo', JSON.stringify(jwt_decode(data['credential'])))
          localStorage.setItem('socialusers', JSON.stringify(this.socialusers));
          if (res.role_name.includes('Admin')) {
            window.location.href = '/admin/dashboard';
          } else if (res.role_name.includes('Manager')) {
            window.location.href = '/manager/dashboard';
          } else if (res.role_name.includes('ESP')) {
            window.location.href = '/esp/emp-status';
          } else {
            localStorage.clear();
            // const info = {
            //   msg: 'You don\'t have permission to login.',
            //   type: 'Alert',
            // };
            // this.alertDialog(info);

            alert('You don\'t have permission to login.');
          }
        });
  }
}
