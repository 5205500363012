import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Socialusers } from 'src/app/models/socialusers';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {
  socialusers = new Socialusers();
  token;
  constructor(public router: Router) {
    this.socialusers = JSON.parse(localStorage.getItem('socialusers'));
    this.token = this.socialusers.access_token;
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.token) {
      return true;
    }
    window.location.href = '/?returnUrl='+state.url;
    return false;
  }

}
